<template>
  <div :class="shapeClass" v-html="htmlData" />
</template>
<script>
import angleBottom from '!!raw-loader!~/components/partials/shapes/angles/angle-bottom.svg';
import angleEnd from '!!raw-loader!~/components/partials/shapes/angles/angle-end.svg';
import angleStart from '!!raw-loader!~/components/partials/shapes/angles/angle-start.svg';
import angleTop from '!!raw-loader!~/components/partials/shapes/angles/angle-top.svg';
import angleLargeTop from '!!raw-loader!~/components/partials/shapes/angles/angle-largeTop.svg';
import blur1 from '!!raw-loader!~/components/partials/shapes/blurs/blur-1.svg';
import blur2 from '!!raw-loader!~/components/partials/shapes/blurs/blur-2.svg';
import blur3 from '!!raw-loader!~/components/partials/shapes/blurs/blur-3.svg';
import blur4 from '!!raw-loader!~/components/partials/shapes/blurs/blur-4.svg';
import curve1 from '!!raw-loader!~/components/partials/shapes/curves/curve-1.svg';
import curve2 from '!!raw-loader!~/components/partials/shapes/curves/curve-2.svg';
import curve3 from '!!raw-loader!~/components/partials/shapes/curves/curve-3.svg';
import curve4 from '!!raw-loader!~/components/partials/shapes/curves/curve-4.svg';
import curve5 from '!!raw-loader!~/components/partials/shapes/curves/curve-5.svg';
import curve6 from '!!raw-loader!~/components/partials/shapes/curves/curve-6.svg';
import curve7 from '!!raw-loader!~/components/partials/shapes/curves/curve-7.svg';
import curve8 from '!!raw-loader!~/components/partials/shapes/curves/curve-8.svg';
import curveDeviceRight from '!!raw-loader!~/components/partials/shapes/curves/curve-device-right.svg';
import wave1 from '!!raw-loader!~/components/partials/shapes/waves/wave-1.svg';

export default {
  props: ['placement', 'shape', 'type', 'color', 'classes'],
  data() {
    return {
      angle: {
        top: angleTop,
        end: angleEnd,
        bottom: angleBottom,
        start: angleStart,
        largeTop: angleLargeTop,
      },
      blur: {
        1: blur1,
        2: blur2,
        3: blur3,
        4: blur4,
      },
      curve: {
        1: curve1,
        2: curve2,
        3: curve3,
        4: curve4,
        5: curve5,
        6: curve6,
        7: curve7,
        8: curve8,
        'device-right': curveDeviceRight,
      },
      wave: {
        1: wave1,
      },
    };
  },
  computed: {
    htmlData() {
      return this[this.shape][this.type];
    },
    shapeClass() {
      return `shape shape-${this.placement} shape-fluid-x svg-shim text-${this.color} ${this.classes}`;
    },
  },
};
</script>
